import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* getDoctors(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getDoctors,
      api_key,
      action.page,
      action.size,
      action.doctorType,
      action.search,
      action.companyId,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DOCTORS_SUCCESS,
      Constants.GET_DOCTORS_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DOCTORS_FAILED, error })
  }
}

function* getDoctorAutocomplete(action: AnyAction) {
  try {
    const response: IResponseStatus = yield call(
      Services.doctorAutocomplete,
      action.name,
      action.page,
      action.limit,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DOCTOR_AUTOCOMPLETE_SUCCESS,
      Constants.GET_DOCTOR_AUTOCOMPLETE_FAILED,
    )
    response.status === 200
      ? action.resolve(response.data)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DOCTOR_AUTOCOMPLETE_FAILED, error })
  }
}

function* addDoctorStep1(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addDoctorStep1,
      api_key,
      action.doctor,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve(response.data.id)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updateDoctorStep1(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updateDoctorStep1,
      action.id,
      api_key,
      action.doctor,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve(response.data.id)
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* addDoctorStep2(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addDoctorStep2,
      api_key,
      action.id,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* addDoctorStep3(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addDoctorStep3,
      api_key,
      action.id,
      action.address,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updateDoctorStep3(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updateDoctorStep3,
      api_key,
      action.id,
      action.addressId,
      action.address,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* addDoctorStep4(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.addDoctorStep4,
      api_key,
      action.id,
      action.doctorInfos,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* updateDoctorStep4(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.updateDoctorStep4,
      api_key,
      action.id,
      action.doctorId,
      action.doctorInfos,
    )

    yield call(parseResponse, response, '', '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: '', error })
  }
}

function* getDoctorsSummary(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)

    const response: IResponseStatus = yield call(
      Services.getDoctorsSummary,
      api_key,
    )

    yield call(
      parseResponse,
      response,
      Constants.GET_DOCTORS_SUMMARY_SUCCESS,
      Constants.GET_DOCTORS_SUMMARY_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.GET_DOCTORS_SUMMARY_FAILED, error })
  }
}

function* deleteDoctor(action: AnyAction) {
  const getApiKey = (state: RootState) => state.authReducer.token

  try {
    const api_key: string = yield select(getApiKey)
    const response: IResponseStatus = yield call(
      Services.deleteDoctor,
      api_key,
      action.id,
    )

    yield call(
      parseResponse,
      response,
      Constants.DELETE_DOCTOR_SUCCESS,
      Constants.DELETE_DOCTOR_FAILED,
    )
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.DELETE_DOCTOR_FAILED, error })
  }
}

export const doctorsSagas = [
  takeLatest(Constants.GET_DOCTORS, getDoctors),
  takeLatest(Constants.GET_DOCTOR_AUTOCOMPLETE, getDoctorAutocomplete),
  takeLatest(Constants.ADD_DOCTOR_STEP_1, addDoctorStep1),
  takeLatest(Constants.ADD_DOCTOR_STEP_2, addDoctorStep2),
  takeLatest(Constants.ADD_DOCTOR_STEP_3, addDoctorStep3),
  takeLatest(Constants.ADD_DOCTOR_STEP_4, addDoctorStep4),
  takeLatest(Constants.GET_DOCTORS_SUMMARY, getDoctorsSummary),
  takeLatest(Constants.UPDATE_DOCTOR_STEP_1, updateDoctorStep1),
  takeLatest(Constants.UPDATE_DOCTOR_STEP_3, updateDoctorStep3),
  takeLatest(Constants.UPDATE_DOCTOR_STEP_4, updateDoctorStep4),
  takeLatest(Constants.DELETE_DOCTOR, deleteDoctor),
]
