import { AlertsRoutes } from '../modules/alerts/routes/routes'
import { AuthRoutes } from '../modules/auth/routes/routes'
import { HomeRoutes } from '../modules/home/routes/routes'
import { PatientsRoutes } from '../modules/patients/routes/routes'
import { DoctorsRoutes } from '../modules/doctors/routes/routes'
import { CenterRoutes } from '../modules/center/routes/routes'
import { messageRoutes } from '../modules/messaging/routes/routes'
import { ManufacturersRoutes } from '../modules/manufacturers/routes/routes'
import { ProtheseRoutes } from '../modules/protheses/routes/routes'
import { BillingRoutes } from '../modules/billing/routes/routes'
import { PrescriptionsRoutes } from '../modules/prescriptions/routes/routes'
import { FullPdfRoutes } from '../modules/pdfViewer/routes/routes'
import { AboutRoutes } from '../modules/about/routes/routes'

const routes = [
  ...HomeRoutes,
  ...AuthRoutes,
  ...AlertsRoutes,
  ...PatientsRoutes,
  ...DoctorsRoutes,
  ...CenterRoutes,
  ...messageRoutes,
  ...ManufacturersRoutes,
  ...ProtheseRoutes,
  ...BillingRoutes,
  ...PrescriptionsRoutes,
  ...FullPdfRoutes,
  ...AboutRoutes,
]

export default routes
