import {
  Close as CloseIcon,
  Save as SaveIcon,
} from '@mui/icons-material'
import {
  Box,
  ClassNameMap,
  Dialog,
  DialogContent,
  DialogTitle,
  FormControl,
  IconButton,
  TextField,
} from '@mui/material'
import { useState } from 'react'
import { toast } from 'react-toastify'
import {
  DoctorType,
  IFoundDoctor,
  INewDoctorAddress,
  INewDoctorInfos,
  INewDoctorStep1,
} from '../../../doctors/reducers'
import Loader from '../../../../modules/common/Loader'
import { IAddImplant, ICenters, IDoctor } from '../../reducers'
import SearchDoctorModal from '../SearchDoctorModal'

interface IProps {
  isOpen: boolean
  classes: ClassNameMap<
    'paperCard' | 'listCard' | 'spanHeaderCard' | 'newNotePaper'
  >
  setIsOpen: React.Dispatch<React.SetStateAction<boolean>>
  addImplant: (infos: IAddImplant) => Promise<unknown>
  setDoctorModal: React.Dispatch<React.SetStateAction<boolean>>
  patientId: number
  center: ICenters
  addDoctor?: {
    step1: (doctor: INewDoctorStep1) => Promise<number>
    step2: (id: number) => Promise<unknown>
    step3: (id: number, address: INewDoctorAddress) => Promise<unknown>
    step4: (id: number, doctorInfos: INewDoctorInfos) => Promise<unknown>
  }
  getCountries?: () => Promise<unknown>
  getDoctorAutocomplete?: (
    name: string,
    page: number,
    limit: number,
  ) => Promise<IFoundDoctor[]>
  getDoctors: (
    size: number,
    page: number,
    doctorType: string,
    search: string,
    companyId?: number,
  ) => Promise<unknown>
  companyId?: number
}

const ModalImplant = (props: IProps) => {
  const [isLoading, setIsLoading] = useState(false) //eslint-disable-line
  const [implantDate, setImplantDate] = useState('')
  const [cardioImplant, setCardioImplant] = useState<IDoctor | null>(null) //eslint-disable-line
  const [cardioAttent, setCardioAttent] = useState<IDoctor | null>(null) //eslint-disable-line
  const [cardioBilling, setCardioBilling] = useState<IDoctor | null>(null) //eslint-disable-line
  const [doctorType, setDoctorType] = useState<DoctorType>('Implant')
  const [isSearchDocOpen, setIsSearchDocOpen] = useState(false)

  const InvoicingField = () => {
    return <span style={{ color: 'limegreen', fontWeight: 'bold' }}>€</span>
  }

  const RequiredField = () => {
    return <span style={{ color: 'red', fontWeight: 'bold' }}>*</span>
  }

  const openSearchDoctor = (type: DoctorType) => {
    setDoctorType(type)
    setIsSearchDocOpen(true)
  }

  const handleDoctorSelection = (doctor: any) => {
    switch (doctorType) {
      case 'Cardiolog':
        setCardioAttent(doctor.row)
        break
      case 'Billing':
        setCardioBilling(doctor.row)
        break
      case 'Implant':
        setCardioImplant(doctor.row)
        break
      default:
        return
    }
    setIsSearchDocOpen(false)
  }

  const handleSubmit = async () => {
    if (cardioImplant && implantDate !== '') {
      setIsLoading(true)
      try {
        await props.addImplant({
          cardio_attent_id: cardioAttent?.id,
          cardio_implant_id: cardioImplant.id,
          cardio_billing_id: cardioBilling?.id,
          center_id: props.center.id,
          patient_id: props.patientId,
          implantation_date: new Date(implantDate).toISOString(),
        })
        toast.success('Implantation ajoutée')
        props.setIsOpen(false)
      } catch (error) {
        console.log(`get error ${JSON.stringify(error)}`)
        toast.error("Erreur lors de l'ajout de l'implantation")
      } finally {
        setIsLoading(false)
      }
    } else
      toast.error(
        <>
          Veuillez remplir les champs obligatoires: <RequiredField />
        </>,
      )
  }

  return (
    <Dialog
      fullWidth={true}
      maxWidth={'sm'}
      open={props.isOpen}
      onClose={() => props.setIsOpen(false)}
      aria-labelledby="responsive-dialog-title"
      sx={{ padding: '30px auto' }}
      PaperProps={{
        style: { borderRadius: 20 },
      }}
    >
      <SearchDoctorModal
        isOpen={isSearchDocOpen}
        setIsOpen={setIsSearchDocOpen}
        handleDoctorSelection={handleDoctorSelection}
        getDoctors={props.getDoctors}
        doctorType={doctorType}
        companyId={props.companyId}
        getCountries={props.getCountries}
        getDoctorAutocomplete={props.getDoctorAutocomplete}
        addDoctor={props.addDoctor}
      />
      <Loader
        text="Enregistrement de la nouvelle implantation"
        open={isLoading}
      />
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
        }}
      >
        <DialogTitle
          id="responsive-dialog-title"
          sx={{
            display: 'flex',
            position: 'relative',
            justifyContent: 'center',
            background: 'linear-gradient(60deg, #84c2ce, #96dcea)',
            color: 'white',
            fontWeight: '700',
            fontSize: '24px',
          }}
        >
          {'Ajouter une implantation'}
          <IconButton
            sx={{ position: 'absolute', right: '20px' }}
            onClick={() => props.setIsOpen(false)}
          >
            <CloseIcon sx={{ color: 'white' }} />
          </IconButton>
        </DialogTitle>
        <DialogContent
          sx={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
            width: '100%',
          }}
        >
          <FormControl sx={{ height: '100%', width: '100%' }}>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '20px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Date d'implantation <RequiredField /> :
              </span>
              <TextField
                // InputProps={{ inputProps: { max: new Date().toISOString().slice(0, 10) } }}
                margin="dense"
                type="date"
                variant="standard"
                value={implantDate}
                onChange={(event) => setImplantDate(event.target.value)}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue implanteur <RequiredField /> :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={
                  cardioImplant
                    ? `${cardioImplant.firstName} ${cardioImplant.userName}`
                    : ''
                }
                onClick={() => openSearchDoctor('Implant')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue traitant :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={
                  cardioAttent
                    ? `${cardioAttent.firstName} ${cardioAttent.userName}`
                    : ''
                }
                onClick={() => openSearchDoctor('Cardiolog')}
              />
            </div>
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                marginTop: '10px',
              }}
            >
              <span style={{ whiteSpace: 'nowrap', marginRight: '10px' }}>
                Cardiologue facturant la télésurveillance <InvoicingField /> :
              </span>
              <TextField
                inputProps={{ style: { cursor: 'pointer' } }}
                sx={{ cursor: 'pointer' }}
                margin="dense"
                type="text"
                fullWidth
                variant="standard"
                value={
                  cardioBilling
                    ? `${cardioBilling.firstName} ${cardioBilling.userName}`
                    : ''
                }
                onClick={() => openSearchDoctor('Billing')}
              />
            </div>
          </FormControl>
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'flex-end',
              width: '100%',
              marginTop: '15px',
            }}
          >
            <IconButton onClick={handleSubmit} sx={{ alignSelf: 'right' }}>
              <SaveIcon />
            </IconButton>
          </Box>
        </DialogContent>
      </div>
    </Dialog>
  )
}

export default ModalImplant
