import { PropsFromReduxPrescriptions } from '../containers/PrescriptionsContainer'
import { Box, Checkbox, Tab, Tabs } from '@mui/material'
import Loader from '../../common/Loader'
import React, { useEffect, useState } from 'react'
import { PrescriptionsState } from '../services'
import PaginationComponent from '../../common/PaginationComponent'
import {
  DataGridPro,
  GridColumns,
  GridValueGetterParams,
} from '@mui/x-data-grid-pro'
import { Toolbar } from './Table/Tollbar'
import { CustomTooltip } from '../../../utils/CustomTooltip'
import { companyClickableIconsNameMap } from '../../../utils/constants'
import { PictureAsPdf } from '@mui/icons-material'
import PdfPreview from '../../../utils/PdfPreview'
import history from '../../../routes/history'
import { PRESCRIPTION_URL } from '../../../helpers/env'
import { IPrescriptions } from '../reducers'
import { toast } from 'react-toastify'

interface IPreviewPosition {
  x: number
  y: number
  document_ref?: string | null
}
const PrescriptionComponent = (
  props: PropsFromReduxPrescriptions & {
    patientId?: number
    forceReload?: boolean
  },
) => {
  const { userSettings } = { ...props }
  const initialColumnVisiblity = { col6: false, select: isMakerAdmin() }
  const intialPrescriptionPreviewPosition: IPreviewPosition = {
    x: 0,
    y: 0,
    document_ref: null,
  }
  const [delayPdfHover, setDelayPdfHover] = useState(setTimeout(() => {}, 1))
  const [columVisibility, setColumnVisibility] = useState(
    initialColumnVisiblity,
  )
  const [data, setData] = useState<IPrescriptions[]>([])
  const [activeTab, setActiveTab] = useState(userSettings.activeTab)
  const [rowsPerPage, setRowsPerPage] = useState(userSettings.rowsPerPage)
  const [activePage, setActivePage] = useState(userSettings.activePage)
  const [loading, setLoading] = useState<boolean>(true)
  const [prescriptionPreviewPosition, setPrescriptionPreviewPosition] =
    useState(intialPrescriptionPreviewPosition)
  const [selectAll, setSelectAll] = useState<boolean>(false)

  function getPrescriptionStateActive(): PrescriptionsState {
    if (isMakerAdmin()) {
      switch (activeTab) {
        case 0:
          return PrescriptionsState.SIGNED
        case 1:
          return PrescriptionsState.SIGNED
        case 2:
          return PrescriptionsState.REFUSED
        case 3:
          return PrescriptionsState.GENERATED
        case 4:
          return PrescriptionsState.MISSINGDATA
        default:
          return PrescriptionsState.SIGNED
      }
    } else {
      switch (activeTab) {
        case 0:
          return PrescriptionsState.SIGNED
        case 1:
          return PrescriptionsState.REFUSED
        case 2:
          return PrescriptionsState.GENERATED
        case 3:
          return PrescriptionsState.MISSINGDATA
        default:
          return PrescriptionsState.SIGNED
      }
    }
  }

  function getPrescriptionReadActive(): boolean {
    return isMakerAdmin() && activeTab === 1
  }

  useEffect(() => {
    if (activeTab !== props.userSettings.activeTab) {
      props.setPrescriptionUserSettings({
        activeTab,
        activePage,
        rowsPerPage,
      })
      fetchData(false).then()
    }
  }, [activeTab]) //eslint-disable-line

  useEffect(() => {
    fetchData(false).then()
  }, [userSettings]) //eslint-disable-line

  useEffect(() => {
    if (rowsPerPage !== props.userSettings.rowsPerPage) {
      props.setPrescriptionUserSettings({ activeTab, activePage, rowsPerPage })
    }
  }, [rowsPerPage]) //eslint-disable-line

  useEffect(() => {
    if (activePage !== props.userSettings.activePage) {
      props.setPrescriptionUserSettings({ activeTab, activePage, rowsPerPage })
      fetchData(false).then()
    }
  }, [activePage]) //eslint-disable-line

  const patientId = props.patientId
  useEffect(() => {
    fetchData(false).then()
  }, [patientId])

  const forceReload = props.forceReload ? props.forceReload : false
  useEffect(() => {
    if (forceReload) {
      fetchData(false).then()
    }
  }, [forceReload])

  const fetchData = async (loader: boolean) => {
    !!loader && setLoading(true)
    try {
      await props.getPrescriptionsSummary(props.patientId || null)
    } catch {
      toast.error('Impossible de charger le nombre de prescriptions')
    } finally {
      setLoading(false)
    }
    !!loader && setLoading(true)
    try {
      await props.getPrescriptions(
        activePage,
        rowsPerPage,
        getPrescriptionStateActive(),
        getPrescriptionReadActive(),
        props.patientId || null,
      )
    } catch {
      toast.error('Impossible de charger les prescriptions')
    } finally {
      setLoading(false)
    }
  }

  useEffect(() => {
    if (props.prescriptionsSummary) {
      setLoading(false)
    } else {
      setLoading(true)
    }
  }, [props.prescriptionsSummary]) //eslint-disable-line

  useEffect(() => {
    !loading ? setData(props.prescriptions.data) : setData([])
  }, [loading, props.prescriptions]) //eslint-disable-line

  useEffect(() => {
    if (!isMakerAdmin() && activeTab === 3) {
      setColumnVisibility({ col6: true, select: false })
    } else if (isMakerAdmin() && activeTab === 4) {
      setColumnVisibility({ col6: true, select: false })
    } else {
      setColumnVisibility(initialColumnVisiblity)
    }
    setSelectAll(false)
  }, [activeTab]) //eslint-disable-line

  const handleTabChange = (_: React.SyntheticEvent, newValue: number) => {
    setData([])
    setActiveTab(newValue)
    setActivePage(1)
  }

  const handleRefresh = () => {
    console.log('request to refresh')
  }

  async function handleDownload(event: any) {
    event.preventDefault()
    for (const prescription of data) {
      if (prescription.checked) {
        const blob: Blob = await props.getPrescriptionPdf(
          prescription.document_ref,
        )
        const fileDownloadUrl = URL.createObjectURL(blob)
        const a = document.createElement('a')
        a.setAttribute('href', fileDownloadUrl)
        a.setAttribute('download', prescription.document_ref)
        a.setAttribute('target', '_blank')
        a.click()
        URL.revokeObjectURL(fileDownloadUrl)
        if (activeTab === 0) {
          props.updatePrescriptionMakerReaded(
            prescription.prescription_id,
            prescription.maker_id,
          )
        }
      }
    }
  }

  function isMakerAdmin(): boolean {
    return (
      props.user?.roles.findIndex((role: { id: number; roleName: string }) => {
        return role.roleName === 'MakerAdmin'
      }) !== -1
    )
  }

  function handelSelectAll() {
    const currentData: IPrescriptions[] = []
    if (data) {
      data.forEach((prescription: any) => {
        const currentPrescription = { ...prescription }
        currentPrescription.checked = !prescription.checked
        currentData.push(currentPrescription)
      })
    }
    setData(currentData)
    setSelectAll(!selectAll)
  }

  function handleSelectChange(event: any) {
    console.log(`row change ${JSON.stringify(event)}`)
    const currentData: IPrescriptions[] = []
    data.forEach((prescription: any) => {
      currentData.push(prescription)
    })
    const index = currentData.findIndex((prescription) => {
      return prescription.id === event.id
    })
    console.log(` prescription index ${index}`)
    const prescription = { ...currentData[index] }
    prescription.checked = !prescription.checked
    currentData[index] = prescription
    setData(currentData)
  }

  const handleClickPrescription = (data: string) => {
    console.log(`documentRef ${data}`)
    clearTimeout(delayPdfHover)
    setPrescriptionPreviewPosition(intialPrescriptionPreviewPosition)
    history.push(`/full-pdf`, {
      path: PRESCRIPTION_URL,
      url: `attached-file/${data}`,
    })
  }

  const onPrescriptionHover = (
    event: React.MouseEvent<HTMLDivElement>,
    row: any,
  ) => {
    setDelayPdfHover(
      setTimeout(() => {
        const height = window.innerHeight * 0.8
        const width = (height * 210) / 297

        const x = event.clientX - (width + 150)
        const y = window.scrollY + 10
        setPrescriptionPreviewPosition({ x, y, document_ref: row.document_ref })
      }, 500),
    )
  }

  const onPrescriptionLeave = () => {
    clearTimeout(delayPdfHover)
    setPrescriptionPreviewPosition(intialPrescriptionPreviewPosition)
  }

  const onViewPatient = (patientId: number) => {
    history.push(`/patients/${patientId}`)
  }
  function a11yProps(index: number) {
    return {
      id: `simple-tab-${index}`,
      'aria-controls': `simple-tabpanel-${index}`,
    }
  }

  const columns: GridColumns = [
    {
      field: 'select',
      renderHeader: () => {
        return (
          <Box
            sx={{
              display: 'flex',
              justifyContent: 'center',
              alignItems: 'center',
            }}
          >
            <span>Sélection</span>
            <CustomTooltip text="Tout sélectionner">
              <Checkbox
                onChange={handelSelectAll}
                checked={selectAll}
              ></Checkbox>
            </CustomTooltip>
          </Box>
        )
      },
      width: 120,
      sortable: false,
      renderCell: (params) => {
        return (
          <Checkbox
            checked={params.row.checked}
            onChange={() => handleSelectChange(params.row)}
          ></Checkbox>
        )
      },
    },
    {
      field: 'col1',
      headerName: 'Nom Prénom',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <div
          onClick={() => {
            onViewPatient(params.row.patient_id)
          }}
          style={{ cursor: 'grab' }}
        >
          <CustomTooltip
            text={
              <p
                style={{
                  margin: 0,
                  fontSize: '13px',
                  overflow: 'hidden',
                }}
              >
                {params.row.patient.patient_name
                  ? params.row.patient.patient_name.toUpperCase()
                  : ''}{' '}
                <br />
                <span style={{ textTransform: 'capitalize' }}>
                  {' '}
                  {params.row.patient.first_name
                    ? params.row.patient.first_name
                    : ''}
                </span>
              </p>
            }
          >
            <Box>
              {params.row.patient.patient_name
                ? params.row.patient.patient_name.toUpperCase()
                : ''}{' '}
              <br />
              <span style={{ textTransform: 'capitalize' }}>
                {' '}
                {params.row.patient.first_name
                  ? params.row.patient.first_name
                  : ''}
              </span>
            </Box>
          </CustomTooltip>
        </div>
      ),
    },
    {
      field: 'col2',
      headerName: 'Numéro de sécurité sociale',
      width: 200,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${params.row.patient.social_insurance_number ? params.row.patient.social_insurance_number : 'non défini'}`,
    },
    {
      field: 'col3',
      headerName: 'Date de prescription',
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.prescription_date
            ? new Date(params.row.prescription_date).toLocaleDateString()
            : ''
        }`,
    },
    {
      field: 'colDuration',
      headerName: 'Période de validité',
      width: 150,
      sortable: false,
      valueGetter: (params: GridValueGetterParams) =>
        `${
          params.row.periodeDeValidite
            ? params.row.periodeDeValidite
            : 'inconnue'
        } mois`,
    },
    {
      field: 'col4',
      headerName: 'Prescripteur',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <CustomTooltip
          text={
            <p
              style={{
                margin: 0,
                fontSize: '13px',
                overflow: 'hidden',
                textTransform: 'uppercase',
              }}
            >
              Dr.{' '}
              {params.row.prescripteur?.user_name
                ? params.row.prescripteur.user_name.toUpperCase()
                : ''}
              <br />
              <span style={{ textTransform: 'capitalize' }}>
                {params.row.prescripteur?.first_name
                  ? params.row.prescripteur.first_name
                  : ''}
              </span>
            </p>
          }
        >
          <Box>
            Dr.{' '}
            {params.row.prescripteur?.user_name
              ? params.row.prescripteur.user_name.toUpperCase()
              : ''}
            <br />
            <span style={{ textTransform: 'capitalize' }}>
              {params.row.prescripteur?.first_name
                ? params.row.prescripteur.first_name
                : ''}
            </span>
          </Box>
        </CustomTooltip>
      ),
    },
    {
      field: 'col5',
      headerName: 'Fournisseur',
      width: 200,
      sortable: false,
      renderCell: (params) =>
        params.row.maker ? (
          companyClickableIconsNameMap[params.row.maker.company_name]
        ) : (
          <></>
        ),
    },
    {
      field: 'col6',
      headerName: 'Données manquantes',
      width: 600,
      sortable: false,
      renderCell: (params) => (
        <div>
          {params.row.error_message
            ?.split(';')
            .map((error: string, index: number) => <p key={index}>{error}</p>)}
        </div>
      ),
    },
    {
      field: 'col7',
      headerName: 'Prescription',
      width: 200,
      sortable: false,
      renderCell: (params) => (
        <div
          style={{
            display: 'flex',
            alignItems: 'start',
            flexDirection: 'column',
          }}
        >
          {params.row.document_ref ? (
            <div
              onMouseEnter={(event) => onPrescriptionHover(event, params.row)}
              onMouseLeave={() => onPrescriptionLeave()}
            >
              <CustomTooltip>
                <div
                  key={params.row.document_ref}
                  className="button-alert-pdf"
                  onClick={() => {
                    handleClickPrescription(params.row.document_ref)
                  }}
                >
                  <PictureAsPdf sx={{ color: 'red', fontSize: '20px' }} />
                  <p
                    style={{
                      margin: 0,
                      fontWeight: 'bold',
                    }}
                  >
                    <span
                      style={{
                        fontSize: '12px',
                        fontWeight: 'bold',
                        color: 'gray',
                      }}
                    >
                      Détails
                    </span>
                  </p>
                </div>
              </CustomTooltip>
            </div>
          ) : (
            'Pas de prescription'
          )}
        </div>
      ),
    },
  ]

  return (
    <>
      <Box sx={{ height: 'calc(100vh - 240px)', width: '100%' }}>
        <Loader open={loading} text="En attente" />
        <Tabs
          TabIndicatorProps={{
            style: {
              backgroundColor: '#ffa726',
              display: 'flexWrap',
            },
          }}
          onChange={handleTabChange}
          textColor="primary"
          value={activeTab}
        >
          {isMakerAdmin() && (
            <Tab
              label={`A charger: ${
                loading ? 0 : props.prescriptionsSummary?.toRead
              }`}
              {...a11yProps(0)}
              wrapped={false}
            />
          )}
          {isMakerAdmin() && (
            <Tab
              label={`Chargées: ${
                loading ? 0 : props.prescriptionsSummary?.read
              }`}
              {...a11yProps(0)}
              wrapped={false}
            />
          )}
          {!isMakerAdmin() && (
            <Tab
              label={`SIGNEES: ${
                loading ? 0 : props.prescriptionsSummary?.signed
              }`}
              {...a11yProps(0)}
              wrapped={false}
            />
          )}

          <Tab
            label={`REFUSEES: ${
              loading ? 0 : props.prescriptionsSummary?.refused
            }`}
            {...a11yProps(1)}
            wrapped={false}
          />
          <Tab
            label={`A SIGNER: ${
              loading ? 0 : props.prescriptionsSummary?.generated
            }`}
            {...a11yProps(2)}
            wrapped={false}
          />
          <Tab
            label={`DONNEES MANQUANTES: ${
              loading ? 0 : props.prescriptionsSummary?.missingData
            }`}
            {...a11yProps(3)}
            wrapped={false}
          />
        </Tabs>
        <DataGridPro
          columns={columns}
          rows={data ? data : []}
          components={{
            Toolbar,
          }}
          pagination={false}
          componentsProps={{
            toolbar: {
              title: 'Prescriptions',
              onRefresh: handleRefresh,
              onDownload: handleDownload,
            },
            pagination: false,
          }}
          pageSize={rowsPerPage}
          hideFooter
          rowsPerPageOptions={undefined}
          rowHeight={60}
          disableColumnMenu
          disableSelectionOnClick
          disableColumnSelector
          columnVisibilityModel={columVisibility}
          sx={{
            border: 'none',
            padding: '3px 15px',
          }}
        />

        <PaginationComponent
          pages={props.prescriptions?.totalPages}
          activePage={activePage}
          rowsPerPage={rowsPerPage}
          setActivePage={setActivePage}
          setRowsPerPage={setRowsPerPage}
        />
        {prescriptionPreviewPosition.document_ref && (
          <PdfPreview
            onClose={() => {
              setPrescriptionPreviewPosition(intialPrescriptionPreviewPosition)
            }}
            style={{
              position: 'absolute',
              top: prescriptionPreviewPosition.y,
              left: prescriptionPreviewPosition.x,
              zIndex: 1000,
            }}
            getPDF={() => {
              return props.getPrescriptionPdf(
                prescriptionPreviewPosition.document_ref
                  ? prescriptionPreviewPosition.document_ref
                  : '',
              )
            }}
          />
        )}
      </Box>
    </>
  )
}

export default PrescriptionComponent
