import { AnyAction } from 'redux'
import { takeLatest, put, call, select } from 'redux-saga/effects'
import * as Constants from '../constants'
import { IResponseStatus, IResponseToken } from '../reducers'
import * as Services from '../services'
import { RootState } from '../../../store'
import { parseResponse } from '../../../store/saga/responseParser'

function* logIn(action: AnyAction) {
  try {
    const response: IResponseStatus = yield call(
      Services.loginUserService,
      action.mail,
      action.pwd,
    )

    yield call(parseResponse, response, Constants.TOGGLE_LOGIN_SUCCESS, '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_LOGIN_FAILED, error })
  }
}

function* loginPSC(action: AnyAction) {
  try {
    const { data }: IResponseToken = yield call(
      Services.getTokenPSCService,
      action.code,
    )
    const response: IResponseStatus = yield call(
      Services.loginPSCService,
      data.access_token,
    )

    yield call(parseResponse, response, Constants.TOGGLE_LOGIN_SUCCESS, '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_LOGIN_FAILED, error })
  }
}

function* resetPwd(action: AnyAction) {
  try {
    const response: IResponseStatus = yield call(
      Services.resetPwdService,
      action.mail,
    )

    yield call(parseResponse, response, Constants.TOGGLE_FORGOT_PWD_SUCCESS, '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_FORGOT_PWD_FAILED, error })
  }
}

function* changePwd(action: AnyAction) {
  const getToken = (state: RootState) => state.authReducer.token
  try {
    const token: string = yield select(getToken)
    const response: IResponseStatus = yield call(
      Services.changePwdService,
      token,
      action.mail,
      action.pwd,
    )

    yield call(parseResponse, response, Constants.TOGGLE_NEWPWD_SUCCESS, '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_NEWPWD_FAILED, error })
  }
}

function* logOut(action: AnyAction) {
  try {
    console.log(`saga will LogOut`)
    const response: IResponseStatus = yield call(Services.logOut)
    yield call(parseResponse, response, Constants.TOGGLE_LOGOUT_SUCCESS, '')
    response.status === 200
      ? action.resolve()
      : action.reject(response.data.error)
  } catch (error) {
    yield put({ type: Constants.TOGGLE_LOGOUT_FAILED, error })
  }
}

export const authSagas = [
  takeLatest(Constants.TOGGLE_LOGIN, logIn),
  takeLatest(Constants.TOGGLE_LOGIN_PSC, loginPSC),
  takeLatest(Constants.TOGGLE_FORGOT_PWD, resetPwd),
  takeLatest(Constants.TOGGLE_NEWPWD, changePwd),
  takeLatest(Constants.TOGGLE_LOGOUT, logOut),
]
