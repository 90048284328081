import { INewDoctor } from 'src/modules/doctors/reducers'
import { ICreatePatient } from 'src/modules/patients/reducers'

export const handleChangePhone = (
  phone: string,
  value: string,
  handleChangePatient: (type: string, value: any) => void,
  patient: ICreatePatient | INewDoctor,
) => {
  //@ts-ignore
  if (value.length > patient[phone].length) {
    if (value.charAt(value.length - 1).match(/\d/))
      switch (value.length) {
        case 5:
          handleChangePatient(phone, value + ' ')
          break
        case 8:
          handleChangePatient(phone, value + ' ')
          break
        case 11:
          handleChangePatient(phone, value + ' ')
          break
        case 14:
          handleChangePatient(phone, value + ' ')
          break
        default:
          handleChangePatient(phone, value)
      }
  } else if (value.length > 3) handleChangePatient(phone, value)
}

export const handleChangeSINumber = (
  value: string,
  handleChangePatient: (type: string, value: any) => void,
) => {
  value = value.replaceAll(' ', '')
  let formatted
  if (value.length && value.charAt(0) !== '1' && value.charAt(0) !== '2') {
    formatted = ''
  } else {
    if (value.length > 1 && value.length <= 3) {
      formatted = `${value.slice(0, 1)} ${value.slice(1)}`
    }
    if (value.length > 3 && value.length <= 5) {
      formatted = `${value.slice(0, 1)} ${value.slice(1, 3)} ${value.slice(3)}`
    }
    if (value.length > 5 && value.length <= 7) {
      formatted = `${value.slice(0, 1)} ${value.slice(1, 3)} ${value.slice(3, 5)} ${value.slice(5)}`
    }
    if (value.length > 7 && value.length <= 10) {
      formatted = `${value.slice(0, 1)} ${value.slice(1, 3)} ${value.slice(3, 5)} ${value.slice(5, 7)} ${value.slice(7)}`
    }
    if (value.length > 10 && value.length <= 13) {
      formatted = `${value.slice(0, 1)} ${value.slice(1, 3)} ${value.slice(3, 5)} ${value.slice(5, 7)} ${value.slice(7, 10)} ${value.slice(10)}`
    }
    if (value.length > 13) {
      formatted = `${value.slice(0, 1)} ${value.slice(1, 3)} ${value.slice(3, 5)} ${value.slice(5, 7)} ${value.slice(7, 10)} ${value.slice(10, 13)} ${value.slice(13)}`
      formatted = formatted.length > 21 ? formatted.slice(0, 21) : formatted
    }
  }
  handleChangePatient('socialInsuranceNumber', formatted)
}

export const getDoctorType = (doctor: any) => {
  let types: string[] = []
  if (doctor.cardioAttent === true) types.push('Cardiologue traitant')
  if (doctor.cardioImplant === true) types.push('Cardiologue implanteur')
  if (doctor.doctorAttent === true) types.push('Médecin traitant')
  return types
}

export const getCenterType = (centerType: any) => {
  switch (centerType) {
    case 'HOSPITAL':
      return 'Hôpital'
    case 'CLINIC':
      return 'Clinique'
    case 'CABINET':
      return 'Cabinet'
    default:
      return centerType
  }
}
