import { GridSortDirection } from '@mui/x-data-grid-pro'
import {
  GET_PATIENT,
  GET_COUNTRY,
  GET_PROSTHESIS_GAMME_RANGE,
  POST_PATIENT,
  GET_PATIENT_BY_ID,
  UPDATE_PATIENT_INFO,
  POST_NEW_NOTE,
  ADD_PATIENT_CENTER,
  POST_NEW_STATUS,
  GET_PATIENTS_SUMMARY,
  ADD_PATIENT_IMPLANT,
  UPDATE_PATIENT_DOCTOR,
  UPDATE_PATIENT_HISTORY,
  UPDATE_PATIENT_TREATMENT,
  UPDATE_IMPLANT,
  DELETE_IMPLANT,
  UPDATE_PATIENT_REASONS,
  GET_TRANSMITTER_REFERENCES,
  CREATE_TRANSMITTER,
  AFFECT_TRANSMITTER,
  POST_AGREEMENT,
  GET_STORED_FILE,
  POST_PATIENT_PRESCRIPTION,
  POST_CRM_ENROLLMENT,
  POST_CRM_SEND,
  PATCH_PATIENT_ALLOW_BILLING,
} from '../constants'

import {
  IAddImplant,
  ICreatePatient,
  INote,
  IPatientInfos,
  IStatus,
  IUpdateImplant,
  PatientType,
  IDoctor,
  IAffectTransmitter,
} from '../reducers'

export function getPatient(
  size: number,
  page: number,
  patientsType: PatientType,
  searchText: string,
  gammeIds: string[],
  makerIds: number[],
  sort: {
    field: string
    sort: GridSortDirection
  } | null,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_PATIENT,
    size,
    page,
    patientsType,
    searchText,
    gammeIds,
    makerIds,
    sort,
    resolve,
    reject,
  }
}

export function getPatientsSummary(resolve: any, reject: any) {
  return { type: GET_PATIENTS_SUMMARY, resolve, reject }
}

export function getCountry(resolve: any, reject: any) {
  return {
    type: GET_COUNTRY,
    resolve,
    reject,
  }
}

export function getProsthesisGammeRange(
  size: number,
  page: number,
  resolve: any,
  reject: any,
) {
  return {
    type: GET_PROSTHESIS_GAMME_RANGE,
    size,
    page,
    resolve,
    reject,
  }
}

export function postAddPatient(
  patient: ICreatePatient,
  resolve: any,
  reject: any,
) {
  return {
    type: POST_PATIENT,
    patient,
    resolve,
    reject,
  }
}

export function getPatientByIdService(id: string, resolve: any, reject: any) {
  return { type: GET_PATIENT_BY_ID, id, resolve, reject }
}

export function updatePatient(
  patient: IPatientInfos,
  resolve: any,
  reject: any,
) {
  return { type: UPDATE_PATIENT_INFO, patient, resolve, reject }
}

export function postNewPatientNote(note: INote, resolve: any, reject: any) {
  return { type: POST_NEW_NOTE, note, reject, resolve }
}

export function postNewPatientStatus(
  status: IStatus,
  resolve: any,
  reject: any,
) {
  return { type: POST_NEW_STATUS, status, reject, resolve }
}

export function addPatientCenter(id: number, resolve: any, reject: any) {
  return { type: ADD_PATIENT_CENTER, id, reject, resolve }
}

export function addImplant(infos: IAddImplant, resolve: any, reject: any) {
  return { type: ADD_PATIENT_IMPLANT, infos, reject, resolve }
}

export function updatePatientDoctor(
  patientId: number,
  doctor: IDoctor,
  resolve: any,
  reject: any,
) {
  return { type: UPDATE_PATIENT_DOCTOR, patientId, doctor, reject, resolve }
}

export function updatePatientHistory(
  patientId: number,
  history: number[],
  resolve: any,
  reject: any,
) {
  return { type: UPDATE_PATIENT_HISTORY, patientId, history, reject, resolve }
}

export function updatePatientTreatment(
  patientId: number,
  treatment: number[],
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_PATIENT_TREATMENT,
    patientId,
    treatment,
    reject,
    resolve,
  }
}

export function updateImplant(
  infos: IUpdateImplant,
  resolve: any,
  reject: any,
) {
  return { type: UPDATE_IMPLANT, infos, reject, resolve }
}

export function deleteImplant(
  patientId: number,
  implantId: number,
  resolve: any,
  reject: any,
) {
  return { type: DELETE_IMPLANT, patientId, implantId, reject, resolve }
}

export function updatePatientReasons(
  implantationId: number,
  reasons: number[],
  resolve: any,
  reject: any,
) {
  return {
    type: UPDATE_PATIENT_REASONS,
    implantationId,
    reasons,
    reject,
    resolve,
  }
}

export function getTransmitterReferences(resolve: any, reject: any) {
  return { type: GET_TRANSMITTER_REFERENCES, reject, resolve }
}

export function createTransmitter(
  referenceId: number,
  serialNumber: string,
  method: 'POST' | 'PATCH',
  resolve: any,
  reject: any,
  transmitterId?: number,
) {
  return {
    type: CREATE_TRANSMITTER,
    referenceId,
    serialNumber,
    method,
    reject,
    resolve,
    transmitterId,
  }
}

export function affectTransmitter(
  infos: IAffectTransmitter,
  method: 'POST' | 'PATCH',
  resolve: any,
  reject: any,
) {
  return { type: AFFECT_TRANSMITTER, infos, method, reject, resolve }
}

export function postAgreement(
  transmitterId: number,
  file: string | ArrayBuffer | null | File,
  resolve: any,
  reject: any,
) {
  return { type: POST_AGREEMENT, transmitterId, file, reject, resolve }
}

export function deleteTransmitter(
  transmitterId: number,
  affectationId: number,
  resolve: any,
  reject: any,
) {
  return { type: POST_AGREEMENT, transmitterId, affectationId, reject, resolve }
}

export function getStoredFile(id: number, resolve: any, reject: any) {
  return { type: GET_STORED_FILE, resolve, reject, id }
}

export function postPatientPrescription(
  patientId: number,
  resolve: any,
  reject: any,
) {
  console.log(`action postPatientPrescription with patientId ${patientId}`)
  return { type: POST_PATIENT_PRESCRIPTION, patientId, resolve, reject }
}

export function postCrmEnrollment(
  implantId: number,
  resolve: number,
  reject: number,
) {
  return { type: POST_CRM_ENROLLMENT, implantId, resolve, reject }
}

export function postCrmSend(
  implantId: number,
  resolve: number,
  reject: number,
) {
  return { type: POST_CRM_SEND, implantId, resolve, reject }
}

export function patchPatientAllowBilling(
  patientId: number,
  allowBilling: boolean,
  resolve: number,
  reject: number,
) {
  return {
    type: PATCH_PATIENT_ALLOW_BILLING,
    patientId,
    allowBilling,
    resolve,
    reject,
  }
}
