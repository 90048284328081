import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  GET_DATA_PATIENT_EXPORT_SUMMARY_SUCCESS,
  GET_DATA_TRANSMITTER_SUMMARY_SUCCESS,
  GET_DATA_PATIENT_SUMMARY_SUCCESS,
  GET_DATA_BILLING_SUMMARY_SUCCESS,
  GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED,
  GET_DATA_TRANSMITTER_SUMMARY_FAILED,
  GET_DATA_PATIENT_SUMMARY_FAILED,
  GET_DATA_BILLING_SUMMARY_FAILED,
  GET_DATA_PRESCRIPTION_SUMMARY_SUCCESS,
  GET_DATA_PRESCRIPTION_SUMMARY_FAILED,
} from '../constants'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export interface IPatientExportSummary {
  newAlertNb: number | null
  newTransmitNb: number | null
  archivedNb: number | null
  missTransmitNb: number | null
}

export interface ITransmitterSummary {
  disconnect: number | null
  inDelivery: number | null
  noPlan: number | null
}
export interface IPatientSummary {
  active: number | null
  toValidate: number | null
  toComplete: number | null
  waitingTransmitter: number | null
  archived: number | null
}
export interface IBillingSummary {
  lateBilling: number | null
  billedCount: number | null
  rejectedBillsCount: number | null
}

export interface IPrescriptionSummary {
  signed: number | null
  refused: number | null
  generated: number | null
  missingData: number | null
  read?: number | null
  toRead?: number | null
}

const initStatePatientExportSummary = {
  newAlertNb: null,
  newTransmitNb: null,
  archivedNb: null,
  missTransmitNb: null,
} as IPatientExportSummary
const initStateTransmitterSummary = {
  disconnect: null,
  inDelivery: null,
  noPlan: null,
} as ITransmitterSummary
const initStatePatientSummary = {
  active: null,
  toValidate: null,
  toComplete: null,
  waitingTransmitter: null,
  archived: null,
} as IPatientSummary
const initStateBillingSummary = {
  lateBilling: null,
  billedCount: null,
  rejectedBillsCount: null,
} as IBillingSummary
const initStatePrescriptionSummary = {
  signed: null,
  refused: null,
  generated: null,
  missingData: null,
  read: null,
  toRead: null,
} as IPrescriptionSummary

export interface IStateHome {
  initStatePatientExportSummary: IPatientExportSummary
  initStateTransmitterSummary: ITransmitterSummary
  initStatePatientSummary: IPatientSummary
  initStateBillingSummary: IBillingSummary
  initStatePrescriptionSummary: IPrescriptionSummary
}

const initialState = {
  initStatePatientExportSummary,
  initStateTransmitterSummary,
  initStatePatientSummary,
  initStateBillingSummary,
  initStatePrescriptionSummary: {
    signed: 0,
    refused: null,
    generated: null,
    missingData: null,
    read: null,
    toRead: null,
  },
} as IStateHome

const homeDataReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_DATA_PATIENT_EXPORT_SUMMARY_SUCCESS:
      draft.initStatePatientExportSummary.nbNewAlerts =
        action.payload.data.nbNewAlerts
      draft.initStatePatientExportSummary.nbNewTransmissions =
        action.payload.data.nbNewTransmissions
      draft.initStatePatientExportSummary.nbArhived =
        action.payload.data.nbArhived
      draft.initStatePatientExportSummary.missTransmitNb =
        action.payload.data.missTransmitNb
      draft.initStatePatientExportSummary.nbDisconnected =
        action.payload.data.nbDisconnected
      break
    case GET_DATA_PATIENT_EXPORT_SUMMARY_FAILED:
      draft.initStatePatientExportSummary.nbNewAlerts = null
      draft.initStatePatientExportSummary.nbNewTransmissions = null
      draft.initStatePatientExportSummary.nbArhived = null
      draft.initStatePatientExportSummary.missTransmitNb = null
      draft.initStatePatientExportSummary.nbDisconnected = null
      break
    case GET_DATA_TRANSMITTER_SUMMARY_SUCCESS:
      draft.initStateTransmitterSummary.disconnect =
        action.payload.data.disconnect
      draft.initStateTransmitterSummary.inDelivery =
        action.payload.data.inDelivery
      draft.initStateTransmitterSummary.noPlan = action.payload.data.noPlan
      break
    case GET_DATA_TRANSMITTER_SUMMARY_FAILED:
      draft.initStateTransmitterSummary.disconnect = null
      draft.initStateTransmitterSummary.inDelivery = null
      draft.initStateTransmitterSummary.noPlan = null
      break
    case GET_DATA_PATIENT_SUMMARY_SUCCESS:
      draft.initStatePatientSummary.active = action.payload.data.active
      draft.initStatePatientSummary.toValidate = action.payload.data.toValidate
      draft.initStatePatientSummary.toComplete = action.payload.data.toComplete
      draft.initStatePatientSummary.waitingTransmitter =
        action.payload.data.waitingTransmitter
      draft.initStatePatientSummary.archived = action.payload.data.archived
      break
    case GET_DATA_PATIENT_SUMMARY_FAILED:
      draft.initStatePatientSummary.active = null
      draft.initStatePatientSummary.toValidate = null
      draft.initStatePatientSummary.toComplete = null
      draft.initStatePatientSummary.waitingTransmitter = null
      draft.initStatePatientSummary.archived = null
      break
    case GET_DATA_BILLING_SUMMARY_SUCCESS:
      draft.initStateBillingSummary.lateBilling =
        action.payload.data.lateBilling
      draft.initStateBillingSummary.billedCount =
        action.payload.data.billedCount
      draft.initStateBillingSummary.rejectedBillsCount =
        action.payload.data.rejectedBillsCount
      break
    case GET_DATA_BILLING_SUMMARY_FAILED:
      draft.initStateBillingSummary.lateBilling = null
      draft.initStateBillingSummary.billedCount = null
      draft.initStateBillingSummary.rejectedBillsCount = null

      break
    case GET_DATA_PRESCRIPTION_SUMMARY_SUCCESS:
      draft.initStatePrescriptionSummary = initStatePrescriptionSummary
      console.log(
        `get success ${JSON.stringify(action.payload)} and darft ${JSON.stringify(draft)} `,
      )
      draft.initStatePrescriptionSummary.signed = action.payload.data.signed
      draft.initStatePrescriptionSummary.refused = action.payload.data.refused
      draft.initStatePrescriptionSummary.generated =
        action.payload.data.generated
      draft.initStatePrescriptionSummary.missingData =
        action.payload.data.missingData
      draft.initStatePrescriptionSummary.read = action.payload.data.read
      draft.initStatePrescriptionSummary.toRead = action.payload.data.toRead
      break
    case GET_DATA_PRESCRIPTION_SUMMARY_FAILED:
      draft.initStatePrescriptionSummary = initStatePrescriptionSummary
      draft.initStatePrescriptionSummary.signed = null
      draft.initStatePrescriptionSummary.refused = null
      draft.initStatePrescriptionSummary.generated = null
      draft.initStatePrescriptionSummary.missingData = null
      draft.initStatePrescriptionSummary.read = null
      draft.initStatePrescriptionSummary.toRead = null
      break

    default:
      return draft
  }
})

export default homeDataReducer
