import { Grid, ListItem, Paper } from '@mui/material'
import React from 'react'
import { makeStyles } from '@mui/styles'
import { PropsFromReduxAbout } from '../containers/AboutContainer'
import packageJson from '../../../../package.json'

const useStyles = makeStyles(() => ({
  paperCard: {
    borderRadius: '15px !important',
  },
  listCard: { padding: 0 },
  spanHeaderCard: { marginRight: '5px' },
}))

const AboutComponent = (props: PropsFromReduxAbout) => {
  // @ts-ignore
  const { aboutData } = { ...props }
  console.log(`reach about component ${JSON.stringify(aboutData)}`)
  const classes = useStyles()
  return (
    <>
      <div className="params-card">
        <Paper elevation={2} className={classes.paperCard}>
          <ListItem
            alignItems="flex-start"
            className="home-card-header home-card-row"
            sx={{
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'flex-start',
              flexDirection: 'row',
              marginBottom: '15px',
            }}
          >
            <span className={classes.spanHeaderCard} />A propos
          </ListItem>
          <ListItem className="home-card-title-row">
            <Grid
              container
              direction="column"
              spacing={2}
              sx={{
                minWidth: '350px',
                width: '100%',
              }}
            >
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '16px 0 0 0',
                }}
              >
                {`Application : ${packageJson.name}`}
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '16px 0 0 0',
                }}
              >
                {`Version : ${packageJson.version}`}
              </Grid>
              <Grid
                item
                sx={{
                  display: 'flex',
                  justifyContent: 'center',
                  padding: '16px 0 0 0',
                  marginBottom: '10px',
                }}
              >
                {`Commit : 371afaab`}
              </Grid>
            </Grid>
          </ListItem>
        </Paper>
      </div>
    </>
  )
}

export default AboutComponent
