import { produce } from 'immer'
import { AnyAction } from '@reduxjs/toolkit'
import {
  CREATE_BILL_SUCCESS,
  GET_BILLABLE_PATIENT_SUCCESS,
  GET_BILLABLE_SUMMARY_SUCCESS,
  GET_BILLING_SUCCESS,
} from '../constants'
import { IBillingList, ICenters, IPatientDoctor } from '../../patients/reducers'

export interface IResponseStatus {
  message: string
  error: string
  data: any
  status: number
}

export enum BillingStatus {
  PENDING = 'PENDING',
  PAID = 'PAID',
  REJECTED = 'REJECTED',
}

export enum EtatFacture {
  CREEE = 'CREEE',
  ENREGISTREE = 'ENREGISTREE',
  NON_SECURISEE = 'NON_SECURISEE',
  CHARGEE = 'CHARGEE',
  SEMI_SECURISEE = 'SEMI_SECURISEE',
  SEMI_SECURISEE_DEGRADE = 'SEMI_SECURISEE_DEGRADE',
  SECURISATION_EN_COURS = 'SECURISATION_EN_COURS',
  SECURISEE = 'SECURISEE',
  MISE_EN_LOT = 'MISE_EN_LOT',
  ENVOYEE = 'ENVOYEE',
  ACQUITTEE = 'ACQUITTEE',
  A_REEMETTRE = 'A_REEMETTRE',
  EN_REJET_TECHNIQUE = 'EN_REJET_TECHNIQUE',
  IMPRIMEE = 'IMPRIMEE',
  EN_COURS = 'EN_COURS',
  EN_REJET_RSP = 'EN_REJET_RSP',
  PAYEE = 'PAYEE',
  ENCAISSEE = 'ENCAISSEE',
  EN_ANOMALIE = 'EN_ANOMALIE',
  EN_ANOMALIE_ENCAISSEE = 'EN_ANOMALIE_ENCAISSEE',
  PART_AMC_ANNULEE = 'PART_AMC_ANNULEE',
  REJET_RSP_ACQUITTE = 'REJET_RSP_ACQUITTE',
  RECYCLEE = 'RECYCLEE',
  SUPPRIMEE = 'SUPPRIMEE',
  ABANDONEE = 'ABANDONEE',
}

export interface billingState {
  billablePatients: IBilling
  billableSummary: IBillableSummary
}

export interface IBillableSummary {}

export interface IBilling {
  gender: string
  patientName: string
  firstName: string
  birthDay: string
  address: string
  zipCode: string
  city: string
  countryId: number
  socialInsuranceNumber: string
  phone: string
  historyComment: string
  treatmentComment: string
  ald: boolean
  cpamCenter: string
  phone1: string
  phone2: string
  phone3: string
  implantDate: string
  prosthesisGamme: string
  maker: string
  model: string
  serialNumber: string
  agreement: boolean
  inWaitingTransmiter: boolean
  billable: boolean
  billingAllData: boolean
  lastBillingDate: string
  lastTransmissionDate: string
  doctorIsSet: boolean
  doctorName: string
  doctorFirstName: string
  lastStatus: string
  centers: ICenters[]
  patientDoctors: IPatientDoctor[]
  billingList: IBillingList[]
  pendingBill: IBill[]
}

export interface IBill {
  id: BigInt
  created_at?: Date
  deleted_at?: Date
  billing_date?: Date
  status?: BillingStatus
  patient_id?: BigInt
  billing_doctor_id?: BigInt
  amount?: number
  billing_detail: IBillingDetails[]
}

export interface IBillingDetails {
  id: BigInt
  version?: Date
  etat_facture: EtatFacture
  pub_id: String
  numero: number
  date_des_soins?: Date
  prise_en_charge?: any
  mode_securisation: any
  billing_id: BigInt
  paiement: any[]
  billing_amount?: any
  soins: any[]
}

const initialState = {
  billableSummary: null,
  billablePatient: null,
}

const billingReducer = produce((draft = initialState, action: AnyAction) => {
  switch (action.type) {
    case GET_BILLING_SUCCESS:
      draft.billing = action.payload.data
      break
    case GET_BILLABLE_PATIENT_SUCCESS:
      draft.billablePatient = action.payload.data
      break
    case GET_BILLABLE_SUMMARY_SUCCESS:
      draft.billableSummary = action.payload.data
      break
    case CREATE_BILL_SUCCESS:
      const obj = { ...draft.billablePatient }
      const d = [...obj.data]

      for (let i = 0; i < d.length; i++) {
        const p = d[i]
        if (p.id === action.payload.data.patient_id) {
          d[i].pendingBill.push(action.payload.data)
          return
        }
      }
      draft.billablePatient = obj
      break
    default:
      return draft
  }
})

export default billingReducer
