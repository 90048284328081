import { Route, Switch } from 'react-router-dom'
import { ConnectedRouter } from 'connected-react-router'
import routes from './routes'
import history from './history'
import React, { useState, useEffect } from 'react'
import { connect } from 'react-redux'

import SidebarContainer from '../modules/common/containers/SidebarContainer'

export const NavbarContext = React.createContext({})

const mapStateToProps = (state) => {
  return {
    isLogged: state.authReducer.isLogged,
  }
}

const connector = connect(mapStateToProps)

function Routes(props) {
  const [isOpen, setIsOpen] = useState(false)

  const authRoutes = ['login', 'forgot-paswword']

  useEffect(() => {
    if (
      props.isLogged === true &&
      ['/login', '/register'].includes(history.location.pathname)
    ) {
      history.push('/')
    }

    if (
      props.isLogged === false &&
      (['/login', '/register', '/about'].includes(history.location.pathname) ===
        false ||
        history.location.pathname === '/')
    ) {
      history.push('/login')
    }
  }, [props.isLogged, props.user])

  return (
    <ConnectedRouter history={history}>
      <NavbarContext.Provider value={{ isOpen, setIsOpen }}>
        <SidebarContainer />
        <Switch>
          {/* eslint-disable-next-line*/}
          {routes.map((route, i) => {
            if (authRoutes.includes(route.key))
              return <Route key={i} {...route} />
          })}
          <div className={isOpen ? 'queries' : 'queries-close'}>
            {/* eslint-disable-next-line*/}
            {routes.map((route, i) => {
              if (!authRoutes.includes(route.key))
                return <Route key={i} {...route} />
            })}
          </div>
        </Switch>
      </NavbarContext.Provider>
    </ConnectedRouter>
  )
}

export default connector(Routes)
