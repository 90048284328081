import AboutContainer from '../containers/AboutContainer'
import * as aboutRoutes from './about'

export const AboutRoutes = [
  {
    key: 'about',
    exact: true,
    path: aboutRoutes.ABOUT,
    component: AboutContainer,
  },
]
