import { connect, ConnectedProps } from 'react-redux'
import AboutComponent from '../components/AboutComponent'
import { withRouter } from 'react-router-dom'
import { RootState } from '../../../store'

const mapStateToProps = (state: RootState) => {
  console.log(`mapStateToProps`)
  return {
    token: state.authReducer.token,
    user: state.authReducer.user,
  }
}

const mapDispatchToProps = (dispatch: any) => {
  console.log(`mapDispatchToProps ${JSON.stringify(dispatch)}`)
  return {}
}

const connector = connect(mapStateToProps, mapDispatchToProps)
export type PropsFromReduxAbout = ConnectedProps<typeof connector>
export default withRouter(connector(AboutComponent))
